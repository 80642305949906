import React, { useEffect } from "react";
import { Seo } from "../../components/seo";
import Layout from "../../hoc/Layout/Layout";

import AboutUsContent from "../../components/AboutUsContent/AboutUsContent";
import { navigate } from "gatsby";

// markup
const IndexPage = () => {

	useEffect(() => {
		const city = localStorage.getItem('city');
		if (city !== 'linkoping') {
			navigate('/dreamclean-culture')
		}
	}, []);

	return (
		<Layout>
			<Seo title="Om oss - Dreamclean" />
			<AboutUsContent />
		</Layout>
	);
};

export default IndexPage;
