import React from "react";
import "./AboutUsContent.scss";
import LikeIcon from "../../assets/images/icons/like.svg";
import GradeSection from "../GradeSection/GradeSection";

const AboutUsContent = () => {
	return (
		<>
			<div className="AboutUsContent">
				<div className="top-section">
					<h3>VÅR VISION</h3>
					<h1>Att skapa en enklare vardag för människor.</h1>
					<h3>
						Vår affärsidé går ut på att vara en personlig städfirma och erbjuda
						högsta kvalitet av städning till låga priser.
					</h3>
				</div>
				<div className="bg-grey-50 about-us-section">
					<h2>Nöjda städare = Nöjda kunder</h2>
					<p>
						Med olika nationaliteter från världens alla hörn. Dreamcleans
						professionella städare är en grupp fantastiskt talangfulla människor
						från hela världen som alla strävar efter att skapa en enklare vardag
						med guldkant!
					</p>
				</div>
				<div className="about-us-section">
					<h2>Våra grundläggande värderingar</h2>
					<p>
						Våra grundläggande värderingar formar vårt företagsklimat och
						vägleder oss när vi fattar beslut. De finns alltid i våra tankar och
						handlingar och skapar en gemenskap på där människor från olika
						länder, kulturer och med olika bakgrunder bemöter varandra med
						ömsesidig respekt och arbetar tillsammans.
					</p>
					<div className="hearts">
						<div className="flex">
							<div>
								<LikeIcon />
							</div>
							<div>
								<h4>Ansvar</h4>
								<p>
									Vi tar ansvar för resultaten av våra beslut. Vi sätter och
									uppnår hög standard.
								</p>
							</div>
						</div>
						<div className="flex">
							<div>
								<LikeIcon />
							</div>
							<div>
								<h4>Integritet</h4>
								<p>Vi är ärliga, moraliska och rättvisa.</p>
							</div>
						</div>
						<div className="flex">
							<div>
								<LikeIcon />
							</div>
							<div>
								<h4>Respekt</h4>
								<p>
									Vi respekterar andra, varandra och gör vårt bästa för att bygga ömsesidigt förtoende.
								</p>
							</div>
						</div>
						<div className="flex">
							<div>
								<LikeIcon />
							</div>
							<div>
								<h4>Kvalitet</h4>
								<p>
									Vi levererar alltid de bästa i allt vi gör, Vi strävar ständigt efter att bli bättre.
								</p>
							</div>
						</div>
						<div className="flex">
							<div>
								<LikeIcon />
							</div>
							<div>
								<h4>Teamwork</h4>
								<p>
									Vi respekterar och litar på varandra för att nå goda resultat. Vi trivs med att arbeta tillsammans och
									stödjer varandra mot samma vision: Att skapa en enklare vardag för människor.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<GradeSection />
		</>
	);
};

export default AboutUsContent;
