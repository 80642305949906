import React from "react";
import "./GradeSection.scss";
import recommended from "../../assets/images/2021Icon.png";

const GradeSection = () => {
  return (
    <>
      <div className="GradeSection">
        <div className="cols">
          <div className="col">
            <div className="top">
              <h3>Alltid fast pris</h3>
              <h1>Inga dolda avgifter</h1>
            </div>
            <h4>
              så att du kan känna dig trygg utan överraskningar på fakturan.
            </h4>
          </div>
          <div className="col">
            <div className="top">
              <h3>Genomförda städningar</h3>
              <h1>83 000</h1>
            </div>
            <h4>och fler ska det bli!</h4>
          </div>
          <div className="col">
            <div className="top">
              <h3>Så tycker våra kunder</h3>
              <h1>4,8 / 5</h1>
            </div>
            <h4>
              Tack vare hög kvalitet, exceptionell service och lyhördhet får vi
              höga betyg av våra kunder.
            </h4>
            <a href="https://www.reco.se/dreamclean-sweden-ab" target="_blanko">
              <img
                src={recommended}
                className="recommended"
                alt="Recommended"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="mobile-divider" />
    </>
  );
};

export default GradeSection;
